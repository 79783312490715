import Vue from "vue";
import Vuex from "vuex";

import { user } from './modules/user';
import { business } from './modules/business';
import { projects } from './modules/projects';
import { subscription } from './modules/subscription';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        business,
        projects,
        subscription,
    },
    state: {
        loading: false,
        valid: true,
    },
    mutations: {
        toggleLoading(state, val) {
            state.loading = val;
        },
        toggleValid(state, val) {
            state.valid = val;
        },
    },
    actions: {}
});
