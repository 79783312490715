<template>
    <v-container @dragleave.self="isDrag = false" class="pa-0">
        <v-card
            outlined
            class="pa-2 text-center"
            v-cloak
            @drop.prevent="dropFile"
            @dragover.prevent
            @dragenter="isDrag = true"
        >
            <v-container class="grey lighten-2">
                <slot name="title"></slot>
            </v-container>
            <v-row align="center" class="text-center">
                <v-col sm="4" class="d-flex justify-center align-center">
                    <Avatar av-size=100 class="avatar" :userId="userId" />
                </v-col>

                <v-col sm="8" class="d-flex justify-center align-center">
                    <v-file-input
                        v-model="file"
                        hide-details="auto"
                        outlined
                        show-size
                        accept="image/*"
                        label="Profile Picture"
                        prepend-inner-icon="mdi-camera"
                        placeholder="Select or Drag an image"
                        :rules="fileRules"
                    ></v-file-input>
                </v-col>
            </v-row>
            <slot name="tip"></slot>
            <v-overlay
                absolute
                :value="isDrag"
            >
                <h1>Drop File</h1>
            </v-overlay>
        </v-card>
    </v-container>
</template>

<script>
import Avatar from '@/components/Avatar';
import { mapMutations, mapState } from 'vuex';

export default {
    components: {
        Avatar
    },
    data () {
        return {
            isDrag: false,
            fileRules: [
                value => !value || value.size < 4000000 || 'Picture size should be less than 4 MB!',
                value => !value || value.type.includes('image') || 'Picture format expected.'
            ]
        };
    },
    computed: {
        ...mapState('user', ['getFile', 'userId']),
        file: {
            set (val) {
                this.setFile(val);
            },
            get () {
                return this.getFile;
            }
        },
    },
    methods: {
        ...mapMutations("user", ['setFile']),
        dropFile (e) {
            this.file = e.dataTransfer.files[0];
            this.isDrag = false;
        }
    }
};
</script>
