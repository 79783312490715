import fetchOlderUserProjects from '@/graphql/queries/fetchOlderUserProjects.gql';

export const projects = {
    namespaced: true,
    state: {
        projects: [],
    },
    mutations: {
        pushProjects(state, projects) {
            for (const project of projects) {
                state.projects.push(project)
            }
        },
        resetProjects(state) {
            state.projects = [];
        }
    },
    actions: {
        fetchProjectsAction({ commit, rootState }, vm) {
            vm.$apollo
                .query({
                    query: fetchOlderUserProjects,
                    update: (data) => data.project,
                    variables: {
                        latestUpdated: vm.latestUpdated,
                        userId: vm.$route.params.userId || rootState.user.userId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((data) => {
                    const fetchedProj = data.data.project;
                    if (process.env.NODE_ENV === 'development')
                        console.log('fetchProjectsAction:', data.data.project);

                    if (
                        fetchedProj.length &&
                        (fetchedProj[0].user.id === vm.$route.params.userId ||
                            fetchedProj[0].user.id === rootState.user.userId)
                    ) {
                        commit('pushProjects', fetchedProj);
                    } else vm.stopFetchingOlder = true;

                    vm.endOfPage = false;
                })
                .catch((error) => {
                    console.error(error);
                    vm.errors = JSON.stringify(error.message);
                });
        },
    },
};
