<template>
    <v-avatar
        :class="{ avatar: avBorder }"
        @click="redirect()"
        tile
        :width="avSize"
        height="auto"
        :style="{ cursor: `${avCursor}` }"
    >
        <v-skeleton-loader
            v-if="$apollo.loading"
            loading
            max-width="100"
            type="avatar"
        ></v-skeleton-loader>
        <img v-else-if="user.picture" alt="Avatar" :src="user.picture" />
        <v-icon v-else x-large>mdi-account-circle</v-icon>
    </v-avatar>
</template>

<script>
import fetchAvatar from '@/graphql/queries/fetchAvatar.gql';

export default {
    props: ['avSize', 'avBorder', 'userId'],
    data () {
        return {
            user: {}
        };
    },
    methods: {
        redirect () {
            if (this.$router.currentRoute.path !== this.profilePath) {
                this.$router.push(this.profilePath);
            }
        }
    },
    computed: {
        profilePath () {
            return `/profile/${this.userId}`;
        },
        avCursor () {
            if (this.$router.currentRoute.path !== this.profilePath) {
                return 'pointer';
            } else {
                return 'default';
            }
        }
    },
    apollo: {
        user: {
            query: fetchAvatar,
            update: data => data.users_by_pk,
            skip () {
                return !this.userId;
            },
            variables () {
                return {
                    userId: this.userId
                };
            },
            // result(data) {
            //     if (process.env.NODE_ENV === 'development') console.log("fetchAvatar", data.data.users_by_pk)
            // },
            error (error) {
                console.error(error);
            }
        }
    }
};
</script>

<style scoped>
.avatar {
    border: 5px solid white;
    box-shadow: 0 0 0 1px lightgrey;
}
</style>
