<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" scrollable persistent overlay-opacity=0.95 max-width="700px">
            <v-card>
                <v-card-text>
                    <ProfileEdit :user="user"></ProfileEdit>
                </v-card-text>

                <v-card-actions class="flex-column action-buttons">
                    <v-btn
                        block
                        x-large
                        @click="editUser"
                        color="green"
                        class="mt-2 white--text"
                        type="submit"
                        :loading="loading"
                        :disabled="!valid"
                    >Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProfileEdit from '@/components/ProfileEdit';

export default {
    props: ['user'],
    components: {
        ProfileEdit
    },
    data () {
        return {
            dialog: true
        };
    },
    computed: {
        ...mapState(['valid', 'loading']),
    },
    methods: {
        ...mapActions('user', ['uploadPicture', 'updateUserProfile']),
        async editUser () {
            await this.uploadPicture(this);
            await this.updateUserProfile(this);

            this.dialog = false;
        }
    }
};
</script>

<style>
    .action-buttons {
        position: -webkit-sticky !important;
        position: sticky !important;
        bottom: 0;
        background-color: white;
    }
</style>
