import fetchSubscription from '@/graphql/queries/fetchSubscription.gql';
import notifyUpdatedSubscription from '@/graphql/queries/notifyUpdatedSubscription.gql';

export const subscription = {
    namespaced: true,
    state: {
        subscription: null,
    },
    mutations: {
        setSubscription(state, value) {
            state.subscription = value;
        },
    },
    actions: {
        // Get Stripe Subscription
        fetchSubscription({ commit }, vm) {
            vm.$apollo
                .query({
                    query: fetchSubscription,
                })
                .then((data) => {
                    vm.$apollo
                        .subscribe({
                            query: notifyUpdatedSubscription,
                        })
                        .subscribe({
                            next(data) {
                                let updatedSubscription =
                                    data.data.subscriptions[0];
                                
                                if (!updatedSubscription) {
                                    updatedSubscription = {product: 'free'};
                                }

                                dataLayer.push({
                                    user_plan: updatedSubscription.product
                                })

                                commit('setSubscription', updatedSubscription);

                                if (process.env.NODE_ENV === 'development')
                                    console.log(
                                        'updatedSubscription',
                                        updatedSubscription
                                    );
                            },
                        });
                })
                .catch((error) => {
                    vm.errors = JSON.stringify(error.message);
                });
        },
    },
};
