<template>
    <v-container v-if="doesSessionExist" class="text-center">
        <ProjectDialog>
            <template v-slot:default="slotProps">
                <v-btn v-on="slotProps.dialog.on" color="green" class="white--text">
                    Create Project <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </ProjectDialog>
    </v-container>
</template>

<script>
import ProjectDialog from '@/components/ProjectDialog';
import { mapState } from 'vuex';

export default {
    components: {
        ProjectDialog
    },
    computed: {
        ...mapState('user', ['doesSessionExist'])
    }
};
</script>
