import SuperTokens from 'supertokens-website';
import fetchAvatar from '@/graphql/queries/fetchAvatar.gql';
import updateUserProfile from '@/graphql/queries/updateUserProfile.gql';

export const user = {
    namespaced: true,
    state: {
        file: null,
        edit: false,
        userId: null,
        doesSessionExist: false,
        isNew: false,
    },
    getters: {
        getFile(state) {
            return state.file;
        },
    },
    mutations: {
        setFile(state, value) {
            state.file = value;
        },
        updateUser(state, { param, value }) {
            state.user[`${param}`] = value;
        },
        toggleEdit(state, val) {
            state.edit = val;
        },
        setUserId(state, val) {
            state.userId = val;

            if (val) {
                dataLayer.push({
                    user_id: val,
                });
            }
        },
        toggleSession(state, val) {
            state.doesSessionExist = val;
        },
        toggleIsNew(state, val) {
            state.isNew = val;
        }
    },
    actions: {
        toBase64({ state }) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(state.file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        async uploadPicture({ dispatch, commit, state }, vm) {
            commit('toggleLoading', true, { root: true });

            if (!state.file) {
                console.log('return');
                return;
            }

            const base64str = await dispatch('toBase64').catch((e) => Error(e));

            const existingImage = vm.user.picture;

            if (base64str instanceof Error) {
                console.error('Base64 Error: ', base64str.message);
                vm.errors = JSON.stringify(base64str.message);
                return;
            }

            const variables = {
                base64str,
                existingImage,
            };

            const url = process.env.VUE_APP_API_LINK;
            const token = (await vm.$auth.getAccessTokenPayloadSecurely()).jwt;
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    query: SET_PROFILE_PICTURE,
                    variables: variables,
                }),
            };

            try {
                const response = await fetch(url, options);
                const data = await response.json();

                vm.user.picture = data.data.setProfilePicture.image_path;
                commit('setFile', null);
                commit('toggleLoading', false, { root: true });
            } catch (err) {
                vm.errors = err.message;
                console.error(err.message);
                commit('toggleLoading', false, { root: true });
            }
        },
        updateUserProfile({ commit, state }, vm) {
            commit('toggleLoading', false, { root: true });

            vm.$apollo
                .mutate({
                    mutation: updateUserProfile,
                    variables: {
                        name: vm.user.name,
                        description: vm.user.description,
                        picture: vm.user.picture,
                        type: vm.user.private.type,
                    },
                    update: (store, { data: { update_users } }) => {
                        try {
                            const data = store.readQuery({
                                query: fetchAvatar,
                                variables: { userId: state.userId },
                            });

                            const newPicture =
                                update_users.returning[0].picture;
                            data.users_by_pk.picture = newPicture;

                            store.writeQuery({
                                query: fetchAvatar,
                                data,
                            });
                        } catch (err) {
                            console.error(err);
                            vm.errors = JSON.stringify(err.message);

                            commit('toggleLoading', false, { root: true });
                        }
                    },
                })
                .then((data) => {
                    if (process.env.NODE_ENV === 'development')
                        console.log(
                            'updateUserProfile',
                            data.data.update_users.returning[0]
                        );

                    commit('toggleEdit', false);
                    commit('toggleLoading', false, { root: true });
                });
        },
        async getUserId({ commit, dispatch }) {
            const userId = await SuperTokens.getUserId();
            commit('setUserId', userId);

            await dispatch('doesSessionExist');
        },
        async doesSessionExist({ commit }) {
            const session = await SuperTokens.doesSessionExist();
            commit('toggleSession', session);
        },
    },
};

// Because we cant import .gql files with fetch
const SET_PROFILE_PICTURE = `
    mutation ($base64str: String!, $existingImage: String!) {
        setProfilePicture(base64str: $base64str, existingImage: $existingImage) {
            image_path
        }
    }
`;
