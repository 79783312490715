import Vue from 'vue';
import Vuetify, { Scroll } from 'vuetify/lib';

Vue.use(Vuetify, {
    directives: {
        Scroll
    }
});

export default new Vuetify({
});
