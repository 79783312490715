<template>
    <div>
        <v-navigation-drawer app clipped v-model="drawer" v-if="$route.path !== '/login'">
            <v-list nav dense>
                <v-list-item-group active-class="">

                    <v-list-item v-if="isAuthenticated" to="/my-designs">
                        <v-list-item-icon>
                            <v-icon>mdi-folder-multiple-image</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>My Designs</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="isAuthenticated" :to="`/profile/${userId}`">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="isAuthenticated" :to="`/billing`">
                        <v-list-item-icon>
                            <v-icon>mdi-credit-card</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Billing</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="isAuthenticated" :to="`/branded-editor`">
                        <v-list-item-icon>
                            <v-icon>mdi-web</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Add on website</v-list-item-title>
                        <!-- <v-list-item-action-text>
                            <v-chip color="green white--text" x-small>NEW</v-chip>
                        </v-list-item-action-text> -->
                    </v-list-item>

                    <v-list-item v-if="isAuthenticated" :to="`/costing-module`">
                        <v-list-item-icon>
                            <v-icon>mdi-currency-usd</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Costing Module</v-list-item-title>
                        <!-- <v-list-item-action-text>
                            <v-chip color="green white--text" x-small>NEW</v-chip>
                        </v-list-item-action-text> -->
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-divider class="my-3"></v-divider>

            <ProjectDialogSidebar></ProjectDialogSidebar>

            <template v-slot:append>
                <v-footer>
                    <!-- <v-spacer></v-spacer> -->
                    <p class="text-overline font-italic text-capitalize">
                        <v-btn text x-small href="https://kitchenwhiz.com/privacy-policy/" target="_blank">Privacy Policy</v-btn>

                        <v-btn text x-small href="https://kitchenwhiz.com/terms-of-service/" target="_blank">Terms of Use</v-btn>
                    </p>
                    <p class="text-caption font-italic text-capitalize">Copyright &copy; 2020 KitchenWhiz Inc. All Rights Reserved</p>
                </v-footer>
            </template>

        </v-navigation-drawer>

        <v-app-bar app clipped-left color="primary" dark>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

            <v-img @click="redirect('/feed')" alt="KitchenWhiz icon" class="shrink ml-0 mt-5 mr-2" max-height="56px" contain
                   src="@/assets/wand.png" :style="{ cursor: 'pointer'}" />
            <v-img @click="redirect('/feed')" alt="KitchenWhiz Logo" class="shrink mr-2" max-height="56px" contain
                   src="@/assets/kw-logo.png" :style="{ cursor: 'pointer'}" />

            <v-spacer></v-spacer>

            <div v-if="isAuthenticated">
                <Avatar class="mr-3 d-none d-sm-inline" av-size=36 :userId="userId"></Avatar>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                            right 
                            class="d-none d-sm-inline" 
                            @click="logout"
                            v-bind="attrs"
                            v-on="on"
                        >mdi-logout</v-icon>
                    </template>
                    <span>Logout</span>
                </v-tooltip>
            </div>
        </v-app-bar>

    </div>
</template>

<script>
import { mapState } from 'vuex';
import Avatar from '@/components/Avatar';
import ProjectDialogSidebar from '@/components/ProjectDialogSidebar';

export default {
    components: {
        Avatar,
        ProjectDialogSidebar,
    },
    name: 'AppBar',
    data () {
        return {
            drawer: null,
            sheet: true,
            homeFeatures: [
                { text: 'Create Kitchen Plans in Minutes' },
                { text: 'No expert design knowleadge required' },
            ],
            proFeatures: [
                { text: 'Increase your design productivity' },
            ]
        };
    },
    computed: {
        ...mapState('user', ['userId', 'doesSessionExist']),
        ...mapState('subscription', ['subscription']),
        isAuthenticated () {
            return this.doesSessionExist;
        },
        isDevEnv() {
            return process.env.NODE_ENV === 'development';
        }
    },
    methods: {
        redirect (page) {
            if (this.$router.currentRoute.path !== page) {
                this.$router.push(page);
            }
        },
        async logout () {
            await this.$auth.signOut();
            this.$store.commit('user/toggleSession', false);
            this.$router.push('/login');
        }
    }
};

</script>
