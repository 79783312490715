import deleteBusinessLocation from '@/graphql/queries/deleteBusinessLocation.gql';
import updateUserBusiness from '@/graphql/queries/updateUserBusiness.gql';

export const business = {
    namespaced: true,
    state: {
        info: {
            id: null,
            subdomain: null,
            distanceUnit: 'km',
            travelDistance: 40,
            countryCode: '',
            email: null,
            phone: null,
            phone164: null,
        },
        locations: [],
        deleteLocations: [],
        isStepperActive: false,
    },
    getters: {
        businessId: (state) => {
            return state.info.id;
        },
    },
    mutations: {
        setBusinessInfo(state, value) {
            state.info = Object.assign({}, state.info, value);
        },
        addLocation(state, value) {
            state.locations.push(value);
        },
        removeLocation(state, value) {
            const index = state.locations.findIndex(
                (loc) => loc.route === value.route
            );
            state.locations.splice(index, 1);
        },
        addDeleteLocations(state, value) {
            state.deleteLocations.push(value);
        },
        removeDeleteLocations(state, index) {
            state.deleteLocations.splice(index, 1);
        },
        setTravelDistance(state, value) {
            state.travelDistance = value;
        },
        calcRadius(state, value) {
            for (const location of state.locations) {
                if (location.circle) {
                    location.circle.setRadius(value);
                }
            }
        },
        toggleStepper(state, value) {
            state.isStepperActive = value;
        },
    },
    actions: {
        updateBusiness({ state, commit, getters }, vm) {
            const businessInfo = state.info;
            const locations = state.locations;
            const deleteLocations = state.deleteLocations;

            const businessLocationObjs = locations.map((loc) => {
                return {
                    route: loc.route,
                    locality: loc.locality,
                    administrative_area_level_1:
                        loc.administrative_area_level_1,
                    administrative_area_level_2:
                        loc.administrative_area_level_2,
                    country: loc.country,
                    location: {
                        type: 'Point',
                        coordinates: [loc.geometry.lat, loc.geometry.lng],
                    },
                };
            });

            if (deleteLocations.length) {
                for (const deleteLocation of deleteLocations) {
                    vm.$apollo
                        .mutate({
                            mutation: deleteBusinessLocation,
                            variables: {
                                business_id: getters.businessId,
                                route: deleteLocation.route,
                            },
                        })
                        .then((data) => {
                            if (process.env.NODE_ENV === 'development')
                                console.log('deleted Location Data:', data);
                        })
                        .catch((err) => {
                            console.error(err.message);
                            vm.errors = JSON.stringify(err.message);
                        });
                }
            }

            vm.$apollo
                .mutate({
                    mutation: updateUserBusiness,
                    variables: {
                        name: businessInfo.name,
                        description: businessInfo.description,
                        email: businessInfo.email,
                        phone: businessInfo.phone164,
                        website: businessInfo.website,
                        distance_unit: businessInfo.distanceUnit,
                        travel_distance: businessInfo.travelDistance,
                        businessLocationObjs,
                        kw_subdomain: businessInfo.subdomain,
                    },
                })
                .then((data) => {
                    if (process.env.NODE_ENV === 'development')
                        console.log('updateUserBusiness Data:', data);

                    commit('toggleLoading', false, { root: true });
                    commit('toggleStepper', false);

                    if (!getters.businessId) {
                        commit('setBusinessInfo', {
                            id: data.data.insert_business.returning[0].id,
                        });
                    }
                    vm.$apollo.queries.domains.refetch();
                })
                .catch((err) => {
                    console.error(err.message);
                    vm.errors = JSON.stringify(err.message);
                    commit('toggleLoading', false, { root: true });
                });
        },
    },
};
