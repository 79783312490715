import router from '@/router'
import SuperTokens from 'supertokens-website';

export const authGuard = async (to, from, next) => {
    if (await SuperTokens.doesSessionExist()) {
        return next();
    } else {
        router.push('/login');
    }
};
