<template>
    <v-dialog v-model="dialog" max-width="600px" scrollable fullscreen>
        <template v-slot:activator="{ on }">
            <slot v-bind:dialog="{ on }"></slot>
        </template>

        <v-form
            ref="form"
            v-model="validComp"
            v-if="dialog"
            @submit.prevent="submitProject"
        >
            <v-btn
                color="blue darken-1"
                class="ma-2 close-btn"
                fab
                x-small
                @click="dialog = false"
            >
                <v-icon color="white">mdi-close</v-icon>
            </v-btn>

            <v-card>
                <v-card-title class="pa-1 justify-center yellow accent-4">
                    <v-col class="text-center pa-0">
                        <h2 class="text-h4 my-2">{{ actionName }}</h2>
                    </v-col>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="12" sm="6">
                                <v-row>
                                    <!-- isPublic -->
                                    <v-row
                                        align="center"
                                        justify="center"
                                        class="mb-5"
                                    >
                                        <v-radio-group
                                            v-model="project.isPublic"
                                            row
                                            hide-details
                                        >
                                            <v-radio
                                                label="Private"
                                                :value="false"
                                            ></v-radio>
                                            <v-radio
                                                label="Public"
                                                :value="true"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-row>

                                    <!-- Title -->
                                    <v-col cols="12" class="py-0">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="project.title"
                                            label="Title*"
                                            required
                                            :rules="titleRules"
                                            :counter="charLimits.title.max"
                                            :min="charLimits.title.min"
                                            :max="charLimits.title.max"
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Description -->
                                    <v-col cols="12" class="py-0">
                                        <v-textarea
                                            v-model="project.description"
                                            dense
                                            outlined
                                            clearable
                                            rows="4"
                                            name="description"
                                            label="Descripe your project"
                                            placeholder="Describe the kitchen shape, style or colors you need."
                                            :rules="descRules"
                                            :counter="charLimits.description"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-alert
                                        v-if="errors"
                                        type="error"
                                        class="mt-3"
                                    >
                                        {{ errors }}
                                    </v-alert>
                                </v-row>
                                <p><small>*indicates required field</small></p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="flex-column">
                    <v-btn
                        block
                        x-large
                        color="green"
                        class="mt-2 white--text"
                        type="submit"
                        :disabled="!validComp"
                        >{{ actionName }}</v-btn
                    >
                    <p class="mt-2 text-caption font-weight-light">
                        By moving forward you agree to our
                        <v-btn
                            text
                            x-small
                            class="pl-0"
                            color="blue lighten-2"
                            href="https://kitchenwhiz.com/privacy-policy/"
                            >Privacy Policy</v-btn
                        >
                    </p>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import insertProject from '@/graphql/queries/insertProject.gql';
import updateProject from '@/graphql/queries/updateProject.gql';
import fetchUserProfile from '@/graphql/queries/fetchUserProfile.gql';
import { mapMutations, mapState } from 'vuex';

export default {
    props: ['editProject'],
    model: {
        prop: 'editProject',
        event: 'editProject',
    },
    data() {
        return {
            dialog: false,
            project: {
                isPublic: false,
                title: '',
                description: '',
                version: undefined,
            },
            charLimits: {
                title: { min: 5, max: 50 },
                description: 300,
            },
            titleRules: [
                (v) => !!v || 'The title is required',
                (v) =>
                    (v &&
                        v.length <= this.charLimits.title.max &&
                        v.length >= this.charLimits.title.min) ||
                    `Title must be between ${this.charLimits.title.min} and ${this.charLimits.title.max} characters`,
            ],
            descRules: [
                (v) =>
                    !v ||
                    v.length <= this.charLimits.description ||
                    `Description must be less than ${this.charLimits.description} characters`,
            ],
            errors: '',
        };
    },
    computed: {
        ...mapState(['valid']),
        ...mapState('user', ['userId']),
        ...mapState('subscription', ['subscription']),
        projectComp: {
            get() {
                return this.project;
            },
            set(val) {
                this.$emit('editProject', val);
            },
        },
        version() {
            if (this.editProject) {
                return this.project.version;
            } else {
                return 2;
            }
        },
        actionName() {
            if (this.editProject) {
                return 'Edit';
            } else {
                return 'Start Designing';
            }
        },
        isPremiumUser() {
            if (this.subscription) {
                const product = this.subscription.product;
                const status = this.subscription.status;
                switch (true) {
                    case product === 'Basic' &&
                        (status === 'active' || status === 'trialing'):
                    case product === 'Pro' &&
                        (status === 'active' || status === 'trialing'):
                    case product === 'Pro Plus' &&
                        (status === 'active' || status === 'trialing'):
                    case product === 'Pro Free' &&
                        (status === 'active' || status === 'trialing'):
                    case product === 'Pro Managed' &&
                        (status === 'active' || status === 'trialing'):
                        return true;
                    default:
                        return false;
                }
            } else return false;
        },
        isProUser() {
            const product = this.subscription.product;
            if (product.startsWith('Pro')) {
                return true;
            } else return false;
        },
        validComp: {
            set(val) {
                this.toggleValid(val);
            },
            get() {
                return this.valid;
            },
        },
    },
    methods: {
        ...mapMutations(['toggleValid']),
        async submitProject() {
            let variables, mutation;
            variables = {
                title: this.project.title,
                description: this.project.description,
                is_public: this.project.isPublic,
            };

            const projectIdVar = { projectId: this.project.id };
            const editorVersion = { editor_version: this.version };

            mutation = this.editProject ? updateProject : insertProject;

            // Add project ID if editing project
            variables = this.editProject
                ? Object.assign(variables, projectIdVar)
                : variables;
            variables = !this.editProject
                ? Object.assign(variables, editorVersion)
                : variables;

            console.log('variables', variables);

            if (this.$refs.form.validate()) {
                // insert/update Project mutation
                await this.$apollo
                    .mutate({ mutation, variables })
                    .then((data) => {
                        let newProjectId;
                        let editedProject;
                        this.dialog = false;

                        if (data.data.insert_project) {
                            // get new public project ID
                            newProjectId =
                                data.data.insert_project.returning[0].id;
                        } else if (data.data.update_project_by_pk) {
                            editedProject = data.data.update_project_by_pk;
                        }

                        if (!this.editProject) {
                            this.$router.push(`/project/${newProjectId}`);
                        } else {
                            // Update project
                            this.projectComp.title = editedProject.title;
                            this.projectComp.description =
                                editedProject.description;
                            this.projectComp.updated_at =
                                editedProject.updated_at;
                            this.projectComp.is_public =
                                editedProject.is_public;
                        }

                        this.$refs.form.resetValidation();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.errors = JSON.stringify(err.message);
                    });
            }
        },
    },
    created() {
        if (this.editProject) {
            this.project = {
                title: this.editProject.title,
                description: this.editProject.description,
                id: this.editProject.id,
                isPublic: this.editProject.is_public,
                version: this.editProject.editor_version,
            };
        }
    },
    apollo: {
        user: {
            query: fetchUserProfile,
            update: (data) => data.users_by_pk,
            variables() {
                return {
                    userId: this.userId,
                };
            },
            skip() {
                return !this.userId;
            },
            // result (data) {

            // },
            error(error) {
                this.errors = JSON.stringify(error.message);
            },
        },
    },
};
</script>

<style scoped>
form {
    position: relative;
}
.close-btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
</style>
