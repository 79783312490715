<template>
    <v-app>
        <AppBar />

        <v-main>
            <v-container class="text-center" v-if="$apollo.loading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-container>

            <v-container class="py-0" fluid v-else>
                <ExtraUserInfo
                    v-if="!type && isAuthenticated"
                    :user="user"
                ></ExtraUserInfo>

                <v-alert v-if="errors" type="error" class="mt-3">
                    {{ errors }}
                </v-alert>
                <router-view></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import AppBar from '@/components/AppBar';
import { mapActions, mapMutations, mapState } from 'vuex';
import ExtraUserInfo from '@/components/ExtraUserInfo';
import fetchUserProfile from '@/graphql/queries/fetchUserProfile.gql';
import fetchUserType from '@/graphql/queries/fetchUserType.gql';
import fetchBusinessWithLocations from '@/graphql/queries/fetchBusinessWithLocations.gql';

export default {
    name: 'App',
    components: {
        ExtraUserInfo,
        AppBar,
    },
    data() {
        return {
            type: 'n/a',
            loadedTags: false,
            errors: '',
        };
    },
    computed: {
        ...mapState('subscription', ['subscription']),
        ...mapState('user', ['userId', 'doesSessionExist', 'isNew']),
        isAuthenticated() {
            return this.doesSessionExist;
        },
        dialog() {
            if (this.user && this.user.private && this.user.private.type) {
                return false;
            } else return true;
        },
    },
    methods: {
        ...mapMutations('user', ['toggleIsNew']),
        ...mapActions('subscription', ['fetchSubscription']),
        ...mapMutations('business', ['setBusinessInfo', 'addLocation']),
        loadTagEvents(email) {
            if (!this.loadedTags && email) {
                this.loadedTags = true;
                dataLayer.push({
                    email: email,
                    event: 'login_event',
                });

                if (this.isNew) {
                    dataLayer.push({
                        event: 'register_event',
                    });

                    this.toggleIsNew(false);
                }

                // if (this.subscription) {
                //     const plan = !this.subscription
                //         ? 'free'
                //         : this.subscription.product;
                //     const timeNow = new Date().getTime();
                //     const createdAt = new Date(
                //         this.subscription.created_at
                //     ).getTime();

                //     fbq('track', 'ViewContent', {
                //         user_plan: plan,
                //         user_type: this.type,
                //     });

                //     if (timeNow - createdAt < 10 * 1000) {
                //         console.log('plan', plan);
                //         let value = 0;
                //         if (plan === 'Basic') {
                //             value = 49;
                //         } else if (plan === 'Pro') {
                //             value = 99;
                //         } else if (plan === 'Pro Plus') {
                //             value = 149;
                //         }

                //         fbq('track', 'Purchase', {
                //             currency: 'USD',
                //             value: value,
                //         });
                //     }
                // }
            }
        },
    },
    mounted() {
        this.$store.dispatch('user/getUserId');
    },
    watch: {
        isAuthenticated(val) {
            this.fetchSubscription(this);
        },
    },
    apollo: {
        user: {
            query: fetchUserProfile,
            update: (data) => data.users_by_pk,
            variables() {
                return { userId: this.userId };
            },
            skip() {
                if (
                    this.$route.query.register ||
                    this.$route.query.login ||
                    this.$route.path === '/'
                )
                    return true;

                if (!this.isAuthenticated) {
                    this.loadTagEvents('');
                    return true;
                } else {
                    return false;
                }
            },
            error(error) {
                this.errors = JSON.stringify(error.message);
            },
            result(data) {
                if (process.env.NODE_ENV === 'development') {
                    console.log('fetchUserProfile', data.data.users_by_pk);
                }

                this.loadTagEvents(data.data.users_by_pk.private.email);
            },
        },
        userType: {
            query: fetchUserType,
            update: (data) => data.user_private,
            skip() {
                return !this.isAuthenticated;
            },
            error(error) {
                this.errors = JSON.stringify(error.message);
            },
            result(data) {
                this.type = data.data.user_private[0].type;

                dataLayer.push({
                    user_type: this.type
                })
                if (process.env.NODE_ENV === 'development')
                    console.log('fetchUserType', this.type);
            },
        },
        business_private: {
            query: fetchBusinessWithLocations,
            variables() {
                return {
                    userId: this.userId,
                };
            },
            skip() {
                return !this.isAuthenticated;
            },
            error(error) {
                this.errors = JSON.stringify(error.message);
            },
            result(data) {
                const businessPriv = data.data.business_private[0];

                if (businessPriv) {
                    let businessInfo = {};

                    let {
                        id,
                        name,
                        description,
                        email,
                        phone,
                        website,
                        business_locations: locations,
                        distance_unit: distanceUnit,
                        travel_distance: travelDistance,
                        status,
                        business_domain: { kw_subdomain: subdomain },
                    } = businessPriv;

                    businessInfo = {
                        id,
                        name,
                        description,
                        email,
                        phone,
                        website,
                        distanceUnit,
                        travelDistance,
                        countryCode: locations[0].country,
                        subdomain,
                    };

                    locations = locations.forEach((location) => {
                        this.addLocation({
                            id: location.id,
                            route: location.route,
                            locality: location.locality,
                            administrative_area_level_1:
                                location.administrative_area_level_1,
                            administrative_area_level_2:
                                location.administrative_area_level_2,
                            country: location.country,
                            geometry: {
                                lat: location.location.coordinates[0],
                                lng: location.location.coordinates[1],
                            },
                        });
                    });
                    this.setBusinessInfo(businessInfo);
                }
            },
        },
    },
};
</script>

<style>
.logo {
    height: 30px;
}

p {
    padding: 0 !important;
    margin: 0 !important;
}
</style>
