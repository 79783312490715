import Vue from 'vue';
import Meta from 'vue-meta';
import store from '@/store/index';
import VueRouter from 'vue-router';
import { authGuard } from '../auth/authGuard';
import SuperTokens from 'supertokens-website';
import { apolloProvider } from '@/graphql/vue-apollo';
import fetchSubscription from '@/graphql/queries/fetchSubscription.gql';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
    {
        path: '/',
        redirect: '/my-designs',
    },
    {
        path: '/feed',
        redirect: '/my-designs',
    },
    {
        path: '/my-designs',
        name: 'My Designs',
        beforeEnter: authGuard,
        component: () => import('../views/MyDesigns.vue'),
        meta: { title: 'My Designs' },
    },
    {
        path: '/profile/:userId',
        name: 'Profile',
        beforeEnter: authGuard,
        component: () => import('../views/Profile.vue'),
        meta: { title: 'Profile' },
    },
    {
        path: '/project/:projectId',
        name: 'Project Page',
        beforeEnter: authGuard,
        component: () => import('../views/Project.vue'),
        meta: { title: 'Project' },
    },
    {
        path: '/branded-editor',
        name: 'Branded Editor',
        beforeEnter: authGuard,
        component: () => import('../views/BrandedEditor.vue'),
        meta: { title: 'Branded Editor' },
    },
    {
        path: '/costing-module',
        name: 'Costing Module',
        beforeEnter: authGuard,
        component: () => import('../views/CostingModule.vue'),
        meta: { title: 'Branded Editor' },
    },
    {
        path: '/billing',
        name: 'Billing',
        beforeEnter: authGuard,
        component: () => import('../views/Billing.vue'),
        meta: { title: 'Billing' },
    },
    {
        path: '/masterclass',
        name: 'MasterClass',
        beforeEnter: authGuard,
        component: () => import('../views/MasterClass.vue'),
        meta: { title: 'Masterclass' },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue'),
        meta: { title: 'Register' },
    },
    {
        path: '/workshop-thank-you',
        name: 'Workshop Registered',
        component: () => import('../views/WorkshopThankYou.vue'),
        meta: { title: 'Workshop Thank You' },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: { title: 'Login' },
    },
    {
        path: '/login/verify',
        name: 'Verify Auth',
        component: () => import('../views/VerifyAuth.vue'),
        meta: { title: 'Verify Auth' },
    },
    {
        path: '/registration-completed',
        name: 'Registration Completed',
        component: () => import('../views/registrationCompleted.vue'),
        meta: { title: 'Registration Completed' },
    },
    {
        path: '*',
        name: '404 Page',
        component: () => import('../views/404page.vue'),
        meta: { title: '404' },
    },
];

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    Vue.nextTick(() => {
        document.title = `${to.meta.title} | Dashboard` || 'Dashboard';
    });

    if (await SuperTokens.doesSessionExist()) {
        if (
            (to.path.includes('profile') && !from.path.includes('profile')) ||
            (from.path.includes('profile') && !to.path.includes('profile'))
        ) {
            store.commit('projects/resetProjects');
            next();
        } else if (to.path === '/login') {
            next('/');
        } else if (from.path.includes('branded-editor') || from.path.includes('costing-module')) {
            store.commit('business/toggleStepper', false);
            next();
        } else next();
    } else {
        if (to.name === 'Register') {
            // Redirect to login
            router.push('/login');
        } else next();
    }
});

export default router;
