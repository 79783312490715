import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import vuetify from './plugins/vuetify';
import moment from 'moment';
import VueApollo from 'vue-apollo';
import { apolloProvider } from '@/graphql/vue-apollo';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { Loader } from 'google-maps';
import Ads from 'vue-google-adsense'
import SuperTokens from 'supertokens-website';

const loaderOptions = { libraries: ['places'], version: 'weekly' };
export const loader = new Loader('AIzaSyBIiXbrYC8m8iiRJ3N61At4qOkPhbaD4f4', loaderOptions);

SuperTokens.init({
    apiDomain: process.env.VUE_APP_AUTH_DOMAIN,
    apiBasePath: '/auth',
    sessionScope: process.env.VUE_APP_SESSION_SCOPE
});
Vue.prototype.$auth = SuperTokens;

Vue.prototype.moment = moment;
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

// Install the authentication plugin here
Vue.use(VueApollo);

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    apolloProvider,
    store,
    render: h => h(App)
}).$mount('#app');
