<template>
    <v-container v-if="!$apollo.loading">
        <v-form v-model="validComp">
            <v-card
                elevation="0"
                class="py-2 mt-2" min-width="250px"
            >
                <!-- Avatar -->
                <ProfileAvatarEdit v-if="!isFeedPage && !isBillingPage">
                    <template v-slot:title>
                        <h3 class="text-h6">Set your profile picture</h3>
                    </template>
                    <template v-slot:tip>
                        <h5 class="text-body-2">Tip: Add your personal picture or your business' logo</h5>
                    </template>
                </ProfileAvatarEdit>

                <!-- Personal Details -->
                <v-card outlined class="pa-2 mt-2 text-center" v-if="!isFeedPage && !isBillingPage">
                    <v-container class="grey lighten-2 mb-3">
                        <h3 class="text-h6">Personal Details</h3>
                    </v-container>

                    <v-row align="center" v-if="userComp">
                        <v-col cols="12" md="6" class="align-center">
                            <v-text-field
                                outlined
                                v-model="userComp.name"
                                :rules="nameRules"
                                :counter="30"
                                label="Personal Name"
                            ></v-text-field>

                            <v-text-field
                                v-if="userComp.private"
                                outlined
                                v-model="userComp.private.email"
                                label="Email"
                                disabled
                                append-icon="mdi-lock"
                            ></v-text-field>

                        </v-col>

                        <v-col cols="12" md="6" class="align-center">
                            <v-textarea
                                outlined
                                v-model="userComp.description"
                                label="Personal Description"
                                hint="Give a short description of your personal interests (optional)"
                                persistent-hint
                                :counter="500"
                            ></v-textarea>
                        </v-col>
                    </v-row>

                </v-card>
                <v-row v-if="userComp && userComp.private">
                    <v-col class="py-0">
                        <v-sheet color="yellow lighten-2" class="pa-3">
                            <p class="text-left text-h6">Use KitchenWhiz for: (REQUIRED)</p>
                            <v-radio-group v-model="userComp.private.type" :rules="typeRules" :mandatory="false" dense class="mt-0">
                                <v-radio value="homeowner">
                                    <template slot="label">
                                        <v-col class="pa-0">
                                            <p class="text-h6 primary--text">Homeowner Use</p>
                                            <p class="text-body-2">Get design ideas and connect with Professional Cabinet Makers</p>
                                        </v-col>
                                    </template>
                                </v-radio>
                                <v-radio value="professional">
                                    <template slot="label">
                                        <v-col class="pa-0">
                                            <p class="text-h6 primary--text">Professional Use</p>
                                            <p class="text-body-2">Increase your design productivity and connect with Homeowners</p>
                                        </v-col>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- Business Details -->
                <slot name="business-edit"></slot>
            </v-card>
        </v-form>

    </v-container>
</template>

<script>
import ProfileAvatarEdit from '@/components/ProfileAvatarEdit';
import { mapMutations, mapState } from 'vuex';

export default {
    components: {
        ProfileAvatarEdit
    },
    props: ['user'],
    model: {
        prop: 'user',
        event: 'updateUser'
    },
    data () {
        return {
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length) <= 30 || 'Name must be less than 30 characters',
                v => (v && v.length) >= 5 || 'Name must be more than 5 characters'
            ],
            typeRules: [
                v => !!v || 'Type is required'
            ]
        };
    },
    computed: {
        ...mapState(['valid']),
        userComp: {
            get() {
                return this.user;
            },
            set({ name, decription, private: {email, type}}) {
                this.$emit('updateUser', { name, decription, private: {email, type}})
            }
        },
        validComp: {
            set (val) {
                this.toggleValid(val);
            },
            get () {
                return this.valid;
            }
        },
        isApplyPage () {
            if (this.$route.path === '/apply') {
                return true;
            } else return false;
        },
        isFeedPage () {
            if (this.$route.path === '/feed') {
                return true;
            } else return false;
        },
        isBillingPage () {
            if (this.$route.path === '/billing') {
                return true;
            } else return false;
        }
    },
    methods: {
        ...mapMutations(['toggleValid'])
    }
};
</script>

<style scoped>
  .avatar {
    border: 5px solid white;
    box-shadow: 0 0 0 1px lightgrey;
  }
</style>
